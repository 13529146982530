:root {
    --ion-font-family: "Fira Sans";
            src: url('../assets/fonts/FiraSans-Regular.ttf');
    --ion-toolbar-background: #ffffff;
    --ion-toolbar-color: var(--ion-color-primary);
    ion-toolbar {
        ion-title {
            color: var(--ion-color-dark);
            font-weight: bold;
        }
    }
    ion-content,
    ion-menu {
        --background: #fff;
    }

    ion-list {
        background: transparent;
    }
    ion-split-pane {
        --border: none;
    }

    ion-header::after {
        display: none;
    }
    ion-item.item-form {
        --background: rgba(0, 0, 0, 0.06);
        margin-bottom: 3em;
        --border-color: #482d79;
    }

    

}



.logo {
    height: 34px;
    margin-top: 4px;
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/FiraSans-Regular.ttf');
  }

@media (max-width: 576px) {
    .hide-xs {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .hide-sm {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .hide-md {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .hide-lg {
        display: none;
    }
}
@media (min-width: 1200px) {
    .hide-xl {
        display: none;
    }
}
.app-title {
    font-size: 40px;
    text-shadow: 2px 2px 2px #aaa;
}

@media (max-width: 799px) {
    .event-popover {
        .popover-content {
            width: 100%;
        }
    }
}
@media (min-width: 800px) {
    .event-popover {
        .popover-content {
            width: 800px;
        }
    }
}
.muted {
    opacity: 0.5;
}
.bottom-popover {
    .popover-content {
        position: fixed;
        /* bottom: 1em; */
        height: 300px;
        width: 300px;
        left: 0 !important;
        top: 0 !important;
    }
}
.deleted-text {
    text-decoration: line-through;
}

.fullscreen-popup {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}
.centerBox {
    max-width: 1024px;
    margin: auto;
}
@media only screen and (max-width: 600px) {
    .responsive-button ion-label {
        display: none !important;
    }
}
.text-pre-wrap {
    white-space: pre-wrap;
}

@media only screen and (min-width: 600px) {
    .booking-popover .popover-content {
        width: 600px;
    }
}

/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
    ion-list {
        background: transparent;
    }
    ion-content,
    ion-tab-bar,
    ion-footer,
    ion-card {
        --background: transparent;
    }
    ion-menu {
        --background: #0d1117;
    }
    ion-card {
        color: #fff;
    }
    ion-item {
        --background: transparent;
        --color: #fff;
    }

    ion-split-pane {
        --border: none;
    }
    ion-card {
        border: 1px solid #1e4273;
        border-radius: 10px;
    }
    ion-modal ion-toolbar {
        border: 1px solid #1e4273;
        background: #121d2f;
    }
    ion-modal ion-content {
        border-left: 1px solid #1e4273;
        border-right: 1px solid #1e4273;
    }
    ion-card-header {
        background: #121d2f;
        border-bottom: 1px solid #1e4273;
    }
    .mainMenu {
        ion-item ion-icon {
            color: #858e97;
        }
        ion-item {
            color: #858e97;
        }
        ion-item,
        ion-list-header {
            --color: #858e97;
        }
        .active-item {
            color: #fff;
        }

        .active-item ion-icon {
            color: #fff;
        }
    }
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
    /*
     * Material Design Dark Theme
     * -------------------------------------------
     */

    .md body.dark {
    }
    .ios body.dark {
    }

    ion-chip {
        cursor: default;
    }
}
// Change Autocomplete Styles in WebKit Browsers
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.swiper-pagination {
    bottom: 0px !important;
}
.fs-custom {
    .ngx-json-viewer {
        font-family: var(--ion-font-family, inherit) !important;
    }
    .segment-key {
        font-weight: 800;
    }
}


